.introHeader {
    padding-top: 3rem;
    font-weight: bold;
    font-size: 2rem;
}

.intro {
    text-align: justify;
    padding: 2rem;
    font-size: 1.3rem;
}

q{
    font-size: 18px
}

.cardOffer {
    transition: transform .2s;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
}

.cardOfferHeight {
    transition: transform .2s;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
    height: 100%;
}

.cardImage {
    width: 100%;
    height: 15vw;
    object-fit: cover;
}

#imgYogakurse {
    object-fit: cover;
    object-position: 0 50%;
}

#imgYogaNatur {
    object-fit: cover;
    object-position: 0 65%;
}

#imgFirmenYoga {
    object-fit: cover;
    object-position: 0 33%;
}

#imgYogaIsmaning {
    object-fit: cover;
    object-position: 0 30%;
}

.cardImageDetail {
    height: 100%;
}

.cardImageDetail2 {
   margin-top: 150px;
}

.cardImageDetailPopup {
    height: 250px;
    object-fit: contain;
}

.cardOffer:hover, .cardOfferHeight:hover {
    transform: scale(1.01);
    /*color: #fff;*/

}

.img1:hover {
    background: linear-gradient(rgba(255,255,255,.5), rgba(255,255,255,.5)),url('../assets/Yoga_Sand_Stone.png') no-repeat;

}

.img2:hover {
    background: linear-gradient(rgba(255,255,255,.5), rgba(255,255,255,.5)),url('../assets/Yoga_Matte.png');
}

.img4:hover {
    background: linear-gradient(rgba(255,255,255,.5), rgba(255,255,255,.5)),url('../assets/Kurse.jpeg');
}

.currentOffer {
    background-color: #adceb3;
    margin: 1rem;
    padding: 2rem;
}

.subtitle {
    font-size: large;
    background-color: #adceb3;
    padding-left: 8px;
    border-top-right-radius: 40px 40px;

}

.offerSec {
    background-color: white;
    box-shadow: rgba(6, 70, 6, 0.2) 0px 7px 29px 0px;
    padding: 2rem;
}

.offerSec .row {
    margin-bottom: 3em;
}

q {
    font-style: italic;
}

.offerSec .card-title,
.offerSec .card-subtitle {
    font-weight: bold;
    padding-top: 2rem;
}
.offerMobile {
    width: 100%;
    margin-bottom: 1em;
}
.accordion {
    width: 80vw;
    margin-bottom: 1em;
}

.accordion-button:not(.collapsed) {
    color: black;
    background-color: #adceb3;
}

.accordion-button:not(.collapsed)::after {
    filter: grayscale(1);
}

.accordion-button:focus {
    border: 1px rgb(170, 169, 169) solid;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.btn-primary {
    background-color: #adceb3;
    color: black;
    border: 3px rgb(243, 242, 242) solid;
    margin: 1rem 0 0 0;
    width: auto;
}

.cardOfferDetail {
    margin: 0 16px 0 16px;
}

.mobileHighlighted {
    color: #adceb3;
}

/*.btnDetails1,
.btnDetails2 {
    margin-top: 5.5rem;
}*/

/*.offerBoxes {
    height: 35vh;
    overflow: auto;
}*/

@media only screen and (max-width: 992px) {

    .offerBoxes {
        height: 50vh;
        overflow: auto;
    }

    .offerSec {
        width: 95vw;
    }
}

@media only screen and (max-width: 768px) {
    .introHeader {
        padding: 1rem;
        font-weight: bold;
        font-size: 1.5rem;
    }

    .intro {
        text-align: justify;
        padding: 1rem;
        font-size: 1rem;


    }
}